/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter add_pb_suffix,eslint_disable,ts_nocheck
// @generated from protobuf file "tower/v1/identity.proto" (package "tower.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message tower.v1.ChallengeRequest
 */
export interface ChallengeRequest {
}
/**
 * @generated from protobuf message tower.v1.ChallengeResponse
 */
export interface ChallengeResponse {
    /**
     * unique identifier for the request to register new identity
     *
     * @generated from protobuf field: string request_id = 1;
     */
    requestId: string;
    /**
     * random challenge to be signed
     *
     * @generated from protobuf field: bytes challenge = 2;
     */
    challenge: Uint8Array;
}
/**
 * @generated from protobuf message tower.v1.AuthenticateRequest
 */
export interface AuthenticateRequest {
    /**
     * unique identifier for the ChallengeRequest
     *
     * @generated from protobuf field: string request_id = 1;
     */
    requestId: string;
    /**
     * EC Public key that correspond to the private key used to make a signature of the challenge
     *
     * @generated from protobuf field: bytes public_key = 2;
     */
    publicKey: Uint8Array;
    /**
     * EC signature of the challenge bytes
     *
     * @generated from protobuf field: bytes signature = 3;
     */
    signature: Uint8Array;
    /**
     * referral reference
     *
     * @generated from protobuf field: string referral = 4;
     */
    referral: string;
}
/**
 * @generated from protobuf message tower.v1.AuthenticateResponse
 */
export interface AuthenticateResponse {
    /**
     * authorization token
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ChallengeRequest$Type extends MessageType<ChallengeRequest> {
    constructor() {
        super("tower.v1.ChallengeRequest", []);
    }
    create(value?: PartialMessage<ChallengeRequest>): ChallengeRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChallengeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChallengeRequest): ChallengeRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ChallengeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ChallengeRequest
 */
export const ChallengeRequest = new ChallengeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChallengeResponse$Type extends MessageType<ChallengeResponse> {
    constructor() {
        super("tower.v1.ChallengeResponse", [
            { no: 1, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "challenge", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<ChallengeResponse>): ChallengeResponse {
        const message = { requestId: "", challenge: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChallengeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChallengeResponse): ChallengeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string request_id */ 1:
                    message.requestId = reader.string();
                    break;
                case /* bytes challenge */ 2:
                    message.challenge = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChallengeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string request_id = 1; */
        if (message.requestId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.requestId);
        /* bytes challenge = 2; */
        if (message.challenge.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.challenge);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ChallengeResponse
 */
export const ChallengeResponse = new ChallengeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateRequest$Type extends MessageType<AuthenticateRequest> {
    constructor() {
        super("tower.v1.AuthenticateRequest", [
            { no: 1, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "public_key", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "signature", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "referral", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateRequest>): AuthenticateRequest {
        const message = { requestId: "", publicKey: new Uint8Array(0), signature: new Uint8Array(0), referral: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateRequest): AuthenticateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string request_id */ 1:
                    message.requestId = reader.string();
                    break;
                case /* bytes public_key */ 2:
                    message.publicKey = reader.bytes();
                    break;
                case /* bytes signature */ 3:
                    message.signature = reader.bytes();
                    break;
                case /* string referral */ 4:
                    message.referral = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string request_id = 1; */
        if (message.requestId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.requestId);
        /* bytes public_key = 2; */
        if (message.publicKey.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.publicKey);
        /* bytes signature = 3; */
        if (message.signature.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.signature);
        /* string referral = 4; */
        if (message.referral !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.referral);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AuthenticateRequest
 */
export const AuthenticateRequest = new AuthenticateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateResponse$Type extends MessageType<AuthenticateResponse> {
    constructor() {
        super("tower.v1.AuthenticateResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateResponse>): AuthenticateResponse {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateResponse): AuthenticateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AuthenticateResponse
 */
export const AuthenticateResponse = new AuthenticateResponse$Type();
/**
 * @generated ServiceType for protobuf service tower.v1.IdentityService
 */
export const IdentityService = new ServiceType("tower.v1.IdentityService", [
    { name: "Challenge", options: { "google.api.http": { post: "/v1/challenge", body: "*" } }, I: ChallengeRequest, O: ChallengeResponse },
    { name: "Authenticate", options: { "google.api.http": { post: "/v1/authenticate", body: "*" } }, I: AuthenticateRequest, O: AuthenticateResponse }
]);
