import './app.scss';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Layout, notification, Row } from 'antd';
import type { ReactElement } from 'react';
import { useEffect } from 'react';

import { esploraApi } from './apis/esplora.api';
import { useTypedDispatch, useTypedSelector } from './app/store';
import { SideMenu } from './common/SideMenu';
import { Routes } from './routes';

const { Content, Footer } = Layout;

function App(): ReactElement<any, any> {
  const dispatch = useTypedDispatch();
  const towerDaemonEndpoint = useTypedSelector(({ settings }) => settings.towerDaemonEndpoint);
  const macaroon = useTypedSelector(({ settings }) => settings.macaroon);
  const isOnboarded = towerDaemonEndpoint && macaroon;

  useEffect(() => {
    (async () => {
      await dispatch(
        esploraApi.endpoints.requestAndSaveLastBlockHeight.initiate(undefined, {
          subscriptionOptions: { pollingInterval: 1000 * 60 },
        })
      ).unwrap();

      // Change global notification config
      notification.config({
        closeIcon: <PlusOutlined />,
      });
    })();
  }, [dispatch]);

  return (
    <Layout id="layout-main">
      {isOnboarded && <SideMenu />}

      <Layout>
        <Content>
          <Row className="app-pages">
            <Col span={22}>
              <Routes />
            </Col>
          </Row>
        </Content>
        <Row>
          <Col span={22} offset={1}>
            <Footer>
              <Row align="middle" justify="space-between">
                <Col>SevenLabs© 2023</Col>
              </Row>
            </Footer>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
}

export default App;
