import { fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const towerApi = createApi({
  reducerPath: 'towerService',
  baseQuery: fakeBaseQuery<string>(),
  tagTypes: ['currency', 'rewardDistribution', 'rewardEra'],
  endpoints: () => ({}),
});
