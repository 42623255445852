import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';

import type {
  AuthenticateResponse,
  ChallengeResponse,
} from '../api-spec/protobuf/gen/js/tower/v1/identity_pb';
import { AuthenticateRequest, ChallengeRequest } from '../api-spec/protobuf/gen/js/tower/v1/identity_pb';
import { IdentityServiceClient } from '../api-spec/protobuf/gen/js/tower/v1/identity_pb.client';
import type { RootState } from '../app/store';
import { retryRtkRequest } from '../utils';

import { towerApi } from './tower.api';

export function selectIdentityClient(state: RootState): IdentityServiceClient {
  return new IdentityServiceClient(
    new GrpcWebFetchTransport({
      baseUrl: state.settings.towerDaemonEndpoint,
    })
  );
}

export const identityApi = towerApi.injectEndpoints({
  endpoints: (build) => ({
    challenge: build.query<ChallengeResponse, void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const client = selectIdentityClient(state);
        return retryRtkRequest(async () => {
          const call = await client.challenge(ChallengeRequest.create());
          return { data: call.response };
        });
      },
    }),
    authenticate: build.query<AuthenticateResponse, AuthenticateRequest>({
      queryFn: async ({ requestId, publicKey, referral, signature }, { getState }) => {
        const state = getState() as RootState;
        const client = selectIdentityClient(state);
        return retryRtkRequest(async () => {
          const call = await client.authenticate(
            AuthenticateRequest.create({
              requestId,
              publicKey,
              referral,
              signature,
            })
          );
          return { data: call.response };
        });
      },
    }),
  }),
});

export const { useChallengeQuery, useAuthenticateQuery } = identityApi;
