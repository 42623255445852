/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter add_pb_suffix,eslint_disable,ts_nocheck
// @generated from protobuf file "tower/v1/identity.proto" (package "tower.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { IdentityService } from "./identity_pb";
import type { AuthenticateResponse } from "./identity_pb";
import type { AuthenticateRequest } from "./identity_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ChallengeResponse } from "./identity_pb";
import type { ChallengeRequest } from "./identity_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * *
 * Identity service spec
 *
 * @generated from protobuf service tower.v1.IdentityService
 */
export interface IIdentityServiceClient {
    /**
     * Request to start the registration process asking for a challenge
     *
     * @generated from protobuf rpc: Challenge(tower.v1.ChallengeRequest) returns (tower.v1.ChallengeResponse);
     */
    challenge(input: ChallengeRequest, options?: RpcOptions): UnaryCall<ChallengeRequest, ChallengeResponse>;
    /**
     * Authenticate using a signature of the challenge as a proof
     *
     * @generated from protobuf rpc: Authenticate(tower.v1.AuthenticateRequest) returns (tower.v1.AuthenticateResponse);
     */
    authenticate(input: AuthenticateRequest, options?: RpcOptions): UnaryCall<AuthenticateRequest, AuthenticateResponse>;
}
/**
 * *
 * Identity service spec
 *
 * @generated from protobuf service tower.v1.IdentityService
 */
export class IdentityServiceClient implements IIdentityServiceClient, ServiceInfo {
    typeName = IdentityService.typeName;
    methods = IdentityService.methods;
    options = IdentityService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Request to start the registration process asking for a challenge
     *
     * @generated from protobuf rpc: Challenge(tower.v1.ChallengeRequest) returns (tower.v1.ChallengeResponse);
     */
    challenge(input: ChallengeRequest, options?: RpcOptions): UnaryCall<ChallengeRequest, ChallengeResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ChallengeRequest, ChallengeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Authenticate using a signature of the challenge as a proof
     *
     * @generated from protobuf rpc: Authenticate(tower.v1.AuthenticateRequest) returns (tower.v1.AuthenticateResponse);
     */
    authenticate(input: AuthenticateRequest, options?: RpcOptions): UnaryCall<AuthenticateRequest, AuthenticateResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateRequest, AuthenticateResponse>("unary", this._transport, method, opt, input);
    }
}
