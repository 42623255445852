import { notification } from 'antd';

import type {
  AverageBalanceResponse,
  FetchRewardResponse,
  InsertTransactionsResponse,
  JoinResponse,
  ListErasResponse,
  ListRewardCurrenciesResponse,
  UnJoinResponse,
  UpdatePayoutInfoResponse,
  UsageResponse,
} from '../api-spec/protobuf/gen/js/tower/v1/reward_pb';
import {
  AverageBalanceRequest,
  FetchRewardRequest,
  InsertTransactionRequest,
  InsertTransactionsRequest,
  JoinRequest,
  ListErasRequest,
  ListRewardCurrenciesRequest,
  UnJoinRequest,
  UpdatePayoutInfoRequest,
  UsageRequest,
} from '../api-spec/protobuf/gen/js/tower/v1/reward_pb';
import type { RootState } from '../app/store';
import type { TestingTowerIdName } from '../features/TxsTesting/testingSlice';
import { selectRewardClient } from '../features/settings/settingsSlice';
import { retryRtkRequest } from '../utils';

import { towerApi } from './tower.api';

export const rewardApi = towerApi.injectEndpoints({
  endpoints: (build) => ({
    join: build.query<JoinResponse, JoinRequest & { walletName: TestingTowerIdName }>({
      queryFn: async ({ info, walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.join(JoinRequest.create({ info }), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          console.log(
            `Join successful in reward era ${call.response.rewardEra}, block ${call.response.blockHeight}`
          );
          notification.success({
            message: `Join successful in reward era ${call.response.rewardEra}, block ${call.response.blockHeight}`,
          });
          return { data: call.response };
        });
      },
    }),
    unJoin: build.query<UnJoinResponse, { walletName: TestingTowerIdName }>({
      queryFn: async ({ walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.unJoin(UnJoinRequest.create(), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          notification.success({ message: 'UnJoin successful' });
          return { data: call.response };
        });
      },
    }),
    updatePayoutInfo: build.query<
      UpdatePayoutInfoResponse,
      UpdatePayoutInfoRequest & { walletName: TestingTowerIdName }
    >({
      queryFn: async ({ info, walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.join(UpdatePayoutInfoRequest.create({ info }), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          return { data: call.response };
        });
      },
    }),
    insertTransaction: build.query<
      InsertTransactionsResponse,
      InsertTransactionRequest & { walletName: TestingTowerIdName }
    >({
      queryFn: async ({ txInfo, walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.insertTransaction(InsertTransactionRequest.create({ txInfo }), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          notification.success({ message: 'Transaction inserted successfully' });
          return { data: call.response };
        });
      },
    }),
    insertTransactions: build.query<
      InsertTransactionsResponse,
      InsertTransactionsRequest & { walletName: TestingTowerIdName }
    >({
      queryFn: async ({ txInfo, walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.insertTransactions(InsertTransactionsRequest.create({ txInfo }), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          return { data: call.response };
        });
      },
    }),
    fetchReward: build.query<FetchRewardResponse, FetchRewardRequest>({
      queryFn: async ({ rewardEra }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.fetchReward(FetchRewardRequest.create({ rewardEra }));
          return { data: call.response };
        });
      },
    }),
    usage: build.query<UsageResponse['usage'], UsageRequest & { walletName: TestingTowerIdName }>({
      queryFn: async ({ rewardEra, walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.usage(UsageRequest.create({ rewardEra }), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          return { data: call.response.usage };
        });
      },
    }),
    averageBalance: build.query<
      AverageBalanceResponse['averageBalance'],
      AverageBalanceRequest & { walletName: TestingTowerIdName }
    >({
      queryFn: async ({ rewardEra, walletName }, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.averageBalance(AverageBalanceRequest.create({ rewardEra }), {
            meta: {
              token: state.testing.wallets[walletName].authenticationToken ?? '',
            },
          });
          return { data: call.response.averageBalance };
        });
      },
    }),
    listRewardCurrencies: build.query<ListRewardCurrenciesResponse, ListRewardCurrenciesRequest>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.listRewardCurrencies(ListRewardCurrenciesRequest.create());
          return { data: call.response };
        });
      },
    }),
    listEras: build.query<ListErasResponse, ListErasRequest>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const client = selectRewardClient(state);
        return retryRtkRequest(async () => {
          const call = await client.listEras(ListErasRequest.create());
          return { data: call.response };
        });
      },
    }),
  }),
});

export const {
  useJoinQuery,
  useUnJoinQuery,
  useUpdatePayoutInfoQuery,
  useInsertTransactionQuery,
  useInsertTransactionsQuery,
  useFetchRewardQuery,
  useUsageQuery,
  useAverageBalanceQuery,
  useListRewardCurrenciesQuery,
  useListErasQuery,
} = rewardApi;
